var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Container",
    { attrs: { title: "PEACH" } },
    [
      _c("b-table", {
        ref: "peachTable",
        staticClass: "py-3 my-5 peachpearplum",
        attrs: {
          borderless: "",
          size: "sm",
          fields: _vm.baseFields,
          bordered: "",
          items: _vm.rows,
          hover: "",
          "head-variant": "vlight",
          "tbody-tr-class": ({ name, selected }, type) =>
            _vm.rowClass({ name, selected }, type),
        },
        on: {
          "row-clicked": ({ name }) => _vm.clickRow(name),
          "row-dblclicked": ({ name }) => _vm.doubleClickRow(name),
        },
        scopedSlots: _vm._u([
          {
            key: "head(selected)",
            fn: function (data) {
              return [
                _c("b-form-checkbox", {
                  attrs: { size: "lg", checked: _vm.allRowsSelected },
                  on: { change: _vm.selectAllRows },
                }),
              ]
            },
          },
          {
            key: "cell(selected)",
            fn: function (data) {
              return [
                _c("b-form-checkbox", {
                  attrs: { size: "lg" },
                  model: {
                    value: data.item.selected,
                    callback: function ($$v) {
                      _vm.$set(data.item, "selected", $$v)
                    },
                    expression: "data.item.selected",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(prepStatus)",
            fn: function (data) {
              return [
                _c("status-display", {
                  attrs: { status: data.item.prepPercentageCompleted },
                }),
              ]
            },
          },
          {
            key: "cell(wrapStatus)",
            fn: function (data) {
              return [
                _c("status-display", {
                  attrs: { status: data.item.wrapPercentageCompleted },
                }),
              ]
            },
          },
          {
            key: "cell(view)",
            fn: function (data) {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "view-button",
                    attrs: { to: `/peach-pear-plum/peach/${data.item.name}` },
                  },
                  [
                    _c(_vm.getLucideIcon(_vm.ICONS.FILE.name), {
                      tag: "component",
                      staticClass: "peachpearplum-view-icon",
                      attrs: { size: 35 },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }