<template>
	<div :class="finalStatus.class" class="pill">
		{{ finalStatus.message }}
	</div>
</template>
<script>
const statusMessages = {
	complete: { message: 'completed', class: 'completed' },
	notComplete: { message: 'non-complete', class: 'non-complete' },
	inProgress: { message: 'partial complete', class: 'partial' },
	notApplicable: { message: 'not applicable', class: 'not-applicable' }
};
export default {
	name: 'StatusDisplay',
	props: {
		status: {
			type: Number,
			default: 0
		},
		notApplicable: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		finalStatus() {
			if (this.notApplicable) return statusMessage['notApplicable'];
			if (this.status === 100) return statusMessages['complete'];
			if (this.status === 0) return statusMessages['notComplete'];
			return statusMessages['inProgress'];
		}
	}
};
</script>
<style scoped lang="scss">
.pill {
	display: inline-flex;
	width: 150px;
	height: 30px;
	padding: 11.5px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.completed {
	background: var(--green-highlight, rgba(158, 215, 212, 0.35));
	color: #75c6c1;
}
.non-complete {
	background: rgba(202, 208, 215, 0.46);
	color: var(--text-light, rgba(6, 38, 62, 0.45));
}
.partial {
	color: rgba(142, 110, 93, 0.63);
	background: rgba(210, 173, 154, 0.35);
}
.not-applicable {
	background: rgba(6, 38, 62, 0.67);
	color: #fff;
}
</style>
