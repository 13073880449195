<template>
	<Container title="PEACH">
		<!-- <router-link v-for="page in pages" :key="page.id" :to="`/reporting/peach/${page.name}`" class="mb-3">{{ page.title }}</router-link> -->
		<b-table
			ref="peachTable"
			borderless
			size="sm"
			:fields="baseFields"
			bordered
			:items="rows"
			class="py-3 my-5 peachpearplum"
			hover
			head-variant="vlight"
			:tbody-tr-class="({ name, selected }, type) => rowClass({ name, selected }, type)"
			@row-clicked="({ name }) => clickRow(name)"
			@row-dblclicked="({ name }) => doubleClickRow(name)"
		>
			<template v-slot:head(selected)="data">
				<b-form-checkbox size="lg" :checked="allRowsSelected" @change="selectAllRows" />
			</template>
			<template v-slot:cell(selected)="data">
				<b-form-checkbox v-model="data.item.selected" size="lg" />
			</template>
			<template v-slot:cell(prepStatus)="data">
				<status-display :status="data.item.prepPercentageCompleted" />
			</template>
			<template v-slot:cell(wrapStatus)="data">
				<status-display :status="data.item.wrapPercentageCompleted" />
			</template>
			<template v-slot:cell(view)="data">
				<router-link :to="`/peach-pear-plum/peach/${data.item.name}`" class="view-button">
					<component :is="getLucideIcon(ICONS.FILE.name)" :size="35" class="peachpearplum-view-icon" />
				</router-link>
			</template>
		</b-table>
	</Container>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import Container from '@/modules/peach-pear-plum/components/Container';
import StatusDisplay from '@/modules/peach-pear-plum/components/StatusDisplay';
import { getPeachResponses } from '@/cruds/peachPearPlum.crud';
import { allQuestions, categories } from '@/modules/peach-pear-plum/peachCategories.js';

export default {
	name: 'PeachDashboard',
	mixins: [languageMessages, globalMixin],
	components: { Container, StatusDisplay },
	data() {
		return {
			highlightRow: null,
			rows: [],
			responsesMappedToCategories: {}
		};
	},
	async mounted() {
		const responses = await getPeachResponses();
		this.prepareRows(responses);
	},
	methods: {
		calculateCategoryTotals() {
			const points = {};
			categories.forEach(({ name, questions }) => {
				const { totalAvailablePoints, totalQuestions } = questions.reduce(this.getTotalsFromCategoriesReducer, {
					totalAvailablePoints: 0,
					totalQuestions: 0
				});
				points[name] = {
					totalAvailablePoints,
					totalQuestions
				};
			});
			return points;
		},
		getTotalsFromCategoriesReducer(acc, cur) {
			if (cur.questions) {
				const availablePoints = cur.questions.reduce(this.getTotalsFromCategoriesReducer, { totalAvailablePoints: 0, totalQuestions: 0 });
				acc.totalAvailablePoints += availablePoints.totalAvailablePoints;
				acc.totalQuestions += cur.questions.length;
				return acc;
			}
			acc.totalAvailablePoints += cur.availablePoints;
			acc.totalQuestions += 1;
			return acc;
		},
		prepareRows(responses) {
			const categoryTotals = this.calculateCategoryTotals();
			// {"id":"2","projectId":28,"questionId":25,"questionSection":"camera","questionSubsection":"","wrap":0,"prep":1,"__typename":"PeachResponse"}
			const responsesMappedToCategories = {};
			responses.forEach(({ questionSection, ...x }) => {
				if (!responsesMappedToCategories[questionSection]) {
					responsesMappedToCategories[questionSection] = [];
				}
				responsesMappedToCategories[questionSection].push(x);
			});
			this.responsesMappedToCategories = responsesMappedToCategories;

			this.rows = Object.entries(categoryTotals).map(([key, val]) => {
				const categoryData = categories.find((x) => x.name === key);
				const totalQuestions = categoryTotals[key].totalQuestions;
				const potentialPoints = categoryTotals[key].totalAvailablePoints;

				let prepPercentageCompleted = 0;
				let prepPoints = 0;
				let prepPercentagePoints = 0;

				let wrapPercentageCompleted = 0;
				let wrapPoints = 0;
				let wrapPercentagePoints = 0;

				if (responsesMappedToCategories[key]) {
					let wrapQuestionsCompleted = 0;
					let prepQuestionsCompleted = 0;

					responsesMappedToCategories[key].forEach(({ wrap, prep, questionId }) => {
						if (wrap === 3 && prep === 3) return;
						if (wrap !== 3) {
							wrapQuestionsCompleted++;
						}
						if (prep !== 3) {
							prepQuestionsCompleted++;
						}
						const { availablePoints } = this.findQuestionByFormId(questionId);
						if (wrap === 1) {
							wrapPoints += availablePoints;
						}
						if (prep === 1) {
							prepPoints += availablePoints;
						}
					});

					prepPercentagePoints = Math.round((prepPoints / potentialPoints) * 100);
					wrapPercentagePoints = Math.round((wrapPoints / potentialPoints) * 100);
					prepPercentageCompleted = Math.round((prepQuestionsCompleted / totalQuestions) * 100);
					wrapPercentageCompleted = Math.round((wrapQuestionsCompleted / totalQuestions) * 100);
				}
				return {
					name: key,
					title: this.FormMSG(categoryData.formId, categoryData.title),
					potentialPoints,
					potentialPointsDuplicate: potentialPoints,
					totalQuestions,
					selected: false,
					entries: responsesMappedToCategories[key],
					prepPercentageCompleted,
					prepPoints,
					prepPercentagePoints,
					wrapPercentageCompleted,
					wrapPoints,
					wrapPercentagePoints
				};
			});
		},

		findQuestionByFormId(questionId) {
			return allQuestions.find((x) => x.formId === questionId);
		},
		selectAllRows() {
			const selected = this.allRowsSelected;
			this.rows.forEach((row) => {
				row.selected = !selected;
			});
		},
		doubleClickRow(record) {
			this.$router.push(`/peach-pear-plum/peach/${record}`);
		},
		clickRow(record) {
			if (this.highlightRow === record) return (this.highlightRow = null);
			this.highlightRow = record;
		},
		rowClass({ name, selected }, type) {
			let className = '';
			if (type !== 'row') return;
			if (name === this.highlightRow) {
				className += 'row-highlighted ';
			}
			if (selected) {
				className += 'row-selected ';
			}
			return className;
		}
	},
	computed: {
		allRowsSelected() {
			if (!this.rows || !this.rows.length) return;
			return this.rows.filter((x) => x.selected).length === this.rows.length;
		},

		baseFields() {
			return [
				{
					key: 'selected',
					label: '',
					sortable: true,
					class: 'select-all align-middle text-center'
				},
				{
					key: 'title',
					label: this.FormMSG(2, 'Category'),
					sortable: true,
					class: 'text-left align-middle'
				},

				{
					key: 'prepStatus',
					label: this.FormMSG(3, 'Prep status'),
					sortable: true,
					class: 'text-center align-middle'
				},
				{
					key: 'prepPercentageCompleted',
					label: this.FormMSG(4, '% Completed'),
					class: 'text-right align-middle',
					sortable: true,
					formatter: (v) => `${v} %`
				},
				{
					key: 'prepPoints',
					label: this.FormMSG(5, 'Potential points'),
					class: 'text-right align-middle',
					sortable: true
				},

				{
					key: 'prepPercentagePoints',
					label: this.FormMSG(12, '% Points estimated'),
					class: 'text-right align-middle',
					sortable: true,
					formatter: (v) => `${v} %`
				},
				{
					key: 'wrapStatus',
					label: this.FormMSG(9, 'Wrap status'),
					sortable: true,
					class: 'text-center align-middle'
				},
				{
					key: 'wrapPercentageCompleted',
					label: this.FormMSG(4, '% Completed'),
					class: 'text-right align-middle',
					sortable: true,
					formatter: (v) => `${v} %`
				},
				{
					key: 'wrapPoints',
					label: this.FormMSG(11, 'Earned points'),
					class: 'text-right align-middle',
					sortable: true
				},
				{
					key: 'wrapPercentagePoints',
					label: this.FormMSG(6, '% Points earned'),
					class: 'text-right align-middle',
					sortable: true,
					formatter: (v) => `${v} %`
				},
				{
					key: 'view',
					label: this.FormMSG(10, 'View'),
					sortable: false,
					class: 'text-center align-middle',
					formatter: (v) => v,
					width: '5%'
				}
			];
		}
	}
};
</script>
