var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pill", class: _vm.finalStatus.class }, [
    _vm._v("\n\t" + _vm._s(_vm.finalStatus.message) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }